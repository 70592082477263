<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
  <b-card title="Réglages">
   
   <b-form
          @submit.prevent
        >
        <b-row>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
                :label="setting.name"
                label-for="Code"
            >
              <b-form-checkbox
                v-model="value"
                class="custom-control-primary"
              >
              </b-form-checkbox>
            </b-form-group>
            <b-form-group
                :label="setting3.name"
                label-for="Code"
            >
              <b-form-checkbox
                v-model="value3"
                class="custom-control-primary"
              >
              </b-form-checkbox>
            </b-form-group>
            <b-form-group
                :label="setting4.name"
                label-for="Code"
            >
              <b-form-checkbox
                v-model="value4"
                class="custom-control-primary"
              >
              </b-form-checkbox>
            </b-form-group>
            <b-form-group
                :label="setting2.name"
                label-for="Code"
            >
              <b-form-checkbox
                v-model="value2"
                class="custom-control-primary"
              >
              </b-form-checkbox>
            </b-form-group>

            <b-button
              @click="saveSettings"
            >
              Enregistrer
            </b-button>
          </b-col>
        </b-row>
      </b-form>

  </b-card>
</b-overlay>
</template>

<script>
import {BModal,BOverlay, BForm,BButton,BRow, BCol, VBModal,BFormCheckbox, BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton,
    BFormCheckbox,
    BForm
  },
  data() {
    return {
      value:null,
      loading:true,
      value2:null,
      setting2:null,
      setting:null,
      value3:null,
      setting3:null,
      value4:null,
      setting4:null,
    }
  },
  watch:{
   
  },
  methods:{
    async saveSettings() {
      this.loading=true
       
      try {
       
       let Parameters={}
       Parameters.name=this.setting.name
       Parameters.value=this.value

       let Parameters2={}
       Parameters2.name=this.setting2.name
       Parameters2.value=this.value2

       let Parameters3={}
       Parameters3.name=this.setting3.name
       Parameters3.value=this.value3

       let Parameters4={}
       Parameters4.name=this.setting4.name
       Parameters4.value=this.value4

        this.setting = await APIRequest.update('settings',1,Parameters)
        this.setting2 = await APIRequest.update('settings',2,Parameters2)
        this.setting3 = await APIRequest.update('settings',3,Parameters3)
        this.setting4 = await APIRequest.update('settings',4,Parameters4)

        this.value2=this.setting2.value==1 ? true : false
        this.value=this.setting.value==1 ? true : false
        this.value3=this.setting3.value==1 ? true : false
        this.value4=this.setting4.value==1 ? true : false

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Les modifications ont bien été enregistrées !',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.loading=false
       
      } catch (error) {
        this.loading=false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Une erreur est survenue.',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
      }
    }
  },
  async mounted()
  {
    this.setting=await APIRequest.detail('settings',1)
    this.value=this.setting.value==1 ? true : false

    this.setting2=await APIRequest.detail('settings',2)
    this.value2=this.setting2.value==1 ? true : false

    this.setting3=await APIRequest.detail('settings',3)
    this.value3=this.setting3.value==1 ? true : false

    this.setting4=await APIRequest.detail('settings',4)
    this.value4=this.setting4.value==1 ? true : false

    this.loading=false
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
